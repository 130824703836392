/*
Author: Nishant Singh
Description: All the constant data which is used in most component
*/

import { isWebPlatform } from './screens';

// .......Don't change the sequence of phaseList
const Config = require('~config/default.env').default;
export const phaseList = [
  { title: 'Mengandung', value: 'expecting', status: 'Mengandung' },
  { title: 'Menyusui', value: 'breastfeeding', status: 'Menyusui' },
  {
    title: 'Memiliki Anak Batita',
    value: 'toddler',
    status: 'Memiliki Anak Batita',
  },
  {
    title: 'Memiliki Anak 3+ Tahun',
    value: 'three_year_kid',
    status: 'Memiliki Anak 3+ Tahun',
  },
  { title: 'Belum Memiliki Anak', value: 'no', status: 'Amazing Woman' },
];

export const motherPhaseList = {
  expecting: { title: 'Mengandung', value: 'expecting', status: 'Mengandung' },
  breastfeeding: {
    title: 'Menyusui',
    value: 'breastfeeding',
    status: 'Menyusui',
  },
  toddler: {
    title: 'Memiliki Anak Batita',
    value: 'toddler',
    status: 'Memiliki Anak Batita',
  },
  three_year_kid: {
    title: 'Memiliki Anak 3+ Tahun',
    value: 'three_year_kid',
    status: 'Memiliki Anak 3+ Tahun',
  },
  no: {
    title: 'Belum Memiliki Anak',
    value: 'no',
    status: { Female: 'Amazing Woman', Male: 'Amazing Man' },
  },
};

export const stateTitle = {
  province: 'Pilih Provinsi',
  district: 'Pilih Kecamatan',
  city: 'Pilih Kota/ Kabupaten',
  'birth-confirmation': 'Konfirmasi Kelahiran',
  'change-username': 'Change Username',
  'change-status': 'Change Status',
  'add-child': 'Masukkan Profil Anak',
  'add-prediction': 'Mengandung',
};

export const optionTypes = [
  { key: 'country', state: 'countries', title: 'Pilih Negara', clear: false },
  {
    key: 'province',
    state: 'provinces',
    title: 'Pilih Provinsi',
    clear: { city: {}, district: {} },
  },
  {
    key: 'city',
    state: 'cities',
    title: 'Pilih Kota/ Kabupaten',
    clear: { district: {} },
  },
  {
    key: 'district',
    state: 'districts',
    title: 'Pilih Kecamatan',
    clear: false,
  },
];

export const SOC = {
  android: 'lulla-android',
  ios: 'lulla-ios',
  web: 'lulla-web-mobile',
};

export const ERROR_CODE = {
  404: 'error_400',
  500: 'error_500',
  503: 'error_503',
};
export const OrderStatus = {
  payment_accepted: {
    borderColor: '#ABE3E4',
    backgroundColor: '#ABE3E4',
  },
  waiting_payment: {
    borderColor: '#A775C8',
    backgroundColor: '#F5EEF9',
  },
  order_in_progress: {
    backgroundColor: '#FCEADE',
    borderColor: '#E3CDB7',
  },
  shipped: {
    backgroundColor: '#D7CDF5',
    borderColor: '#AD9EDA',
  },
  delivered: {
    backgroundColor: '#FCF6BB',
    borderColor: '#E6C358',
  },
  cancelled: {
    backgroundColor: '#F7D6D9',
    borderColor: '#F15B68',
  },
};

export const Duration = [
  {
    isSelected: false,
    text: 'Less than 1 Week',
  },
  {
    isSelected: false,
    text: '1 Week - 1 Month',
  },
  {
    isSelected: false,
    text: '1 - 3 Months',
  },
  {
    isSelected: false,
    text: '3 - 6 Months',
  },
  {
    isSelected: false,
    text: '6 Months - 1 Year',
  },
  {
    isSelected: false,
    text: '> 1 Year',
  },
];

export const Images = [
  require('~assets/img/product/star-gray.svg'),
  require('~assets/img/product/star-purple.svg'),
  require('~assets/img/dummy_product.png'),
  require('~assets/img/product/wishlist-icon-active.svg'),
  require('~assets/img/product/wishlist-icon.svg'),
  require('~assets/img/product/text-bundles.png'),
  require('~assets/img/product/text-exclusive.png'),
  require('~assets/img/common/header-back-icon.svg'),
  require('~assets/img/common/arrow-back.png'),
  require('~assets/img/smartphone.svg'),
];

const iconLilla = `${Config.LULLA_WEB_URL + require('~assets/icon.jpg')}`;
const imageLilla = `${
  Config.LULLA_WEB_URL + require('~assets/logo-lilla-square.jpg')
}`;

export const DEFAULT_META_TAG = {
  title: 'Lilla - The First Ultimate App for Moms',
  description:
    'Bebas ongkir se-Indonesia* & extra voucher untuk  belanja kebutuhan ibu, dari kehamilan, menyusui, pasca-melahirkan hingga kebutuhan Si Kecil dari brand-brand terpercaya, 100% BPOM & SNI.',
  keywords:
    'online,store,terpercaya,beauty,parenting,skincare,kehamilan,menyusui,ibu hamil,ahli anak,mom and baby,promo,perkembangan anak,toko online',
  image: imageLilla,
  logo: iconLilla,
  username: '@sociolla_id',
  url: isWebPlatform ? window.location.href : Config.LULLA_WEB_URL,
};

export const egiftInfo =
  'Pengiriman kartu E-gift card tidak akan dikenakan biaya karena akan dikirimkan melalui email penerima!';
export const egiftConfirm =
  'E-Gift Card akan dikirimkan melalui email/nomor handphone atau keduanya sesuai dengan data yang kamu isi.';
export const downloadLink = {
  appStore: 'https://apps.apple.com/id/app/lilla/id1572422418',
  playStore:
    'https://play.google.com/store/apps/details?id=com.socialbella.lillaapps',
};
export const footerButtons = [
  {
    key: 'app-store',
    url: downloadLink.appStore,
    src: require('~assets/img/home/footer/btn-app-store.png'),
  },
  {
    key: 'play-store',
    url: downloadLink.playStore,
    src: require('~assets/img/home/footer/btn-google-play.png'),
  },
];
export const alreadyLogin = 'You have already logged in.';
export const downloadURL = 'https://lillapp.app.link/DownloadNow';

export const recommend = [
  { isSelected: false, text: 'Yes absolutely!', value: 'yes' },
  { isSelected: false, text: 'No', value: 'no' },
];

export const repurchase = [
  { isSelected: false, text: 'Yes', value: 'yes' },
  { isSelected: false, text: 'Maybe', value: 'maybe' },
  { isSelected: false, text: 'No', value: 'no' },
];

export const trustedReviewTitle = require('~assets/img/my-reviews/trusted-review.svg');
export const iconInfoAltDangerSource = require('~assets/img/common/icon_info_alt_danger.png');

export const starGreySource = require('~assets/img/my-reviews/star-inactive.png');
export const starPurpleSource = require('~assets/img/my-reviews/star-big.png');
export const iconAddPhotoSource = require('~assets/img/my-reviews/icon-add-photo.png');
export const iconAddPhotoSmallSource = require('~assets/img/my-reviews/icon-add-photo-small.png');
export const iconClearPhotoSource = require('~assets/img/my-reviews/clear-photo.png');
export const iconAddPhotoMoreSource = require('~assets/img/my-reviews/icon-add-more.png');

export const iconRecommendMaybeSource = require('~assets/img/my-reviews/icon-recommend-maybe.png');
export const iconRecommendYesSource = require('~assets/img/my-reviews/icon-recommend-yes.png');
export const iconRecommendNoSource = require('~assets/img/my-reviews/icon-recommend-no.png');
export const iconRecommendMaybeSourceActive = require('~assets/img/my-reviews/icon-recommend-maybe-active.png');
export const iconRecommendYesSourceActive = require('~assets/img/my-reviews/icon-recommend-yes-active.png');
export const iconRecommendNoSourceActive = require('~assets/img/my-reviews/icon-recommend-no-active.png');

export const rates = {
  5: [1, 2, 3, 4, 5],
  4: [1, 2, 3, 4],
  3: [1, 2, 3],
  2: [1, 2],
  1: [1],
};

export const limitProduct = [16, 32, 50];

export const skinTypeDataForMoms = {
  skincare: [
    {
      name: 'Normal skin',
      value: 'kulit-normal',
      code: 'normal',
      isSelected: false,
    },
    {
      name: 'Dry skin',
      value: 'kulit-kering',
      code: 'dry',
      isSelected: false,
    },
    {
      name: 'Oily skin',
      value: 'kulit-berminyak',
      code: 'oily',
      isSelected: false,
    },
    {
      name: 'Sensitive skin',
      value: 'kulit-sensitif',
      code: 'sensitive',
      isSelected: false,
    },
    {
      name: 'Combination',
      value: 'combination',
      code: 'combination',
      isSelected: false,
    },
  ],
  bathBody: [
    {
      name: 'Normal',
      value: 'normal',
      code: 'normal',
      isSelected: false,
    },
    {
      name: 'Dry / Kering',
      value: 'dry',
      code: 'dry',
      isSelected: false,
    },
    {
      name: 'Oily / Berminyak',
      value: 'oily',
      code: 'oily',
      isSelected: false,
    },
    {
      name: 'Sensitive',
      value: 'sensitive',
      code: 'sensitive',
      isSelected: false,
    },
    {
      name: 'Combination',
      value: 'combination',
      code: 'combination',
      isSelected: false,
    },
  ],
  fragrance: [
    {
      name: 'Spray',
      value: 'spray',
      code: 'spray',
      isSelected: false,
    },
    {
      name: 'Roll-On',
      value: 'roll-on',
      code: 'roll-on',
      isSelected: false,
    },
    {
      name: 'Stick',
      value: 'stick',
      code: 'stick',
      isSelected: false,
    },
  ],
};

export const concernDataForMoms = {
  skincare: [
    {
      name: 'Acne & Blemishes',
      value: 'acne-blemishes',
      code: 'ab',
      isSelected: false,
    },
    {
      name: 'Aging',
      value: 'aging',
      code: 'aging',
      isSelected: false,
    },
    {
      name: 'Blackheads & Visible Pores',
      value: 'blackheads-visible-pores',
      code: 'bvp',
      isSelected: false,
    },
    {
      name: 'Dullness',
      value: 'dullness',
      code: 'dullness',
      isSelected: false,
    },
    {
      name: 'Dark Spots',
      value: 'dark-spots',
      code: 'ds',
      isSelected: false,
    },
    {
      name: 'Dark-Circles',
      value: 'dark-circles',
      code: 'dc',
      isSelected: false,
    },
  ],
  hairCare: [
    {
      name: 'Hair Loss / Rambut Rontok',
      value: 'hair-loss',
      code: 'hl',
      isSelected: false,
    },
    {
      name: 'Dandruff / Ketombe',
      value: 'dandruff',
      code: 'dandruff',
      isSelected: false,
    },
    {
      name: 'Dry Scalp',
      value: 'dry-scalp',
      code: 'ds',
      isSelected: false,
    },
    {
      name: 'Oiliness / Rambut-berminyak',
      value: 'oiliness',
      code: 'oiliness',
      isSelected: false,
    },
    {
      name: 'Normal',
      value: 'normal',
      code: 'normal',
      isSelected: false,
    },
  ],
  bathBody: [
    {
      name: 'Stretch Marks',
      value: 'stretch-marks',
      code: 'sm',
      isSelected: false,
    },
    {
      name: 'Cellulite',
      value: 'cellulite',
      code: 'cellulite',
      isSelected: false,
    },
    {
      name: 'Sore, Dry & Flakky Nipple',
      value: 'sore-dry-flakky-nipple',
      code: 'sdfn',
      isSelected: false,
    },
  ],
  fragrance: [
    {
      name: 'Floral',
      value: 'floral',
      code: 'floral',
      isSelected: false,
    },
    {
      name: 'Fruity',
      value: 'fruity',
      code: 'fruity',
      isSelected: false,
    },
    {
      name: 'Fresh',
      value: 'fresh',
      code: 'fresh',
      isSelected: false,
    },
    {
      name: 'Earthy-and-woody',
      value: 'earthy-and-woody',
      code: 'eaw',
      isSelected: false,
    },
    {
      name: 'Powdery',
      value: 'powdery',
      code: 'powdery',
      isSelected: false,
    },
  ],
  oralCare: [
    {
      name: 'Sensitive Teeth',
      value: 'sensitive-teeth',
      code: 'st',
      isSelected: false,
    },
    {
      name: 'Cavity',
      value: 'cavity',
      code: 'cavity',
      isSelected: false,
    },
    {
      name: 'Dental Plaque',
      value: 'dental-plaque',
      code: 'dp',
      isSelected: false,
    },
    {
      name: 'Gum Care',
      value: 'gum-care',
      code: 'gc',
      isSelected: false,
    },
    {
      name: 'Freshens Breath/ Fresh-Breath',
      value: 'freshens-breath',
      code: 'fb',
    },
  ],
};

export const skinTypeDataForKids = {};

export const concernDataForKids = {
  oralCare: [
    {
      name: 'Cavity',
      value: 'cavity',
      code: 'cavity',
      isSelected: false,
    },
    {
      name: 'Dental Plaque',
      value: 'dental-plaque',
      code: 'dp',
      isSelected: false,
    },
    {
      name: 'Gum Care',
      value: 'gum-care',
      code: 'gc',
      isSelected: false,
    },
  ],
  babyCare: [
    {
      name: 'Eczema',
      value: 'eczema',
      code: 'eczema',
      isSelected: false,
    },
  ],
  toiletries: [
    {
      name: 'Bug-bite',
      value: 'bug-bite',
      code: 'bb',
      isSelected: false,
    },
  ],
  diaper: [
    {
      name: 'Diaper-rash',
      value: 'diaper-rash',
      code: 'dr',
      isSelected: false,
    },
  ],
  hairCare: [
    {
      name: 'Hair Growth',
      value: 'hair-growth',
      code: 'hg',
      isSelected: false,
    },
    {
      name: 'Dry & Tangled Hair',
      value: 'dry-tangled-hair',
      code: 'dth',
      isSelected: false,
    },
    {
      name: 'Eczema',
      value: 'eczema',
      code: 'eczema',
      isSelected: false,
    },
    {
      name: 'Cradle Cap',
      value: 'cradle-cap',
      code: 'cc',
      isSelected: false,
    },
  ],
};

export const childAge = [
  'Newborn (0-3 months)',
  'Bayi/Baby (4-12 months)',
  'Batita/Infant (1-3 years)',
  'Balita/Toddler (4-5 years)',
  'Kids (6+ years)',
];

export const motherhoodStage = [
  'Pregnancy',
  'Postpartum',
  'Breastfeeding',
  'Others',
];

export const catShowConcern = [
  'Skincare',
  'Hair Care',
  'Bath & Body',
  'Oral Care',
  'Baby Care',
  'Toiletries',
  'Diapers',
  'For Moms',
  'For Baby & Kids',
];

export const onlyGeneralFilters = [
  'Laundry & Detergent',
  'Sanitary',
  'Nursing & Feeding',
  'Accessories',
  'Nail Care',
  'Laundry & Cleaning',
  'Oral Care',
  'Diaper',
];

export const specialSectionDescribe = {
  Fragrance: [
    {
      nameSub: 'Type/Format',
      codeSub: 'Skin/Hair Type',
      pointSub: 'typeFormat',
      typeSection: 'SET_TYPE_FORMAT',
    },
    {
      nameSub: 'Scents',
      codeSub: 'Scent',
      pointSub: 'scents',
      typeSection: 'SET_SCENTS',
    },
  ],
  Travel: [
    {
      nameSub: 'Material',
      codeSub: 'Material',
      pointSub: 'material',
      typeSection: 'SET_MATERIAL',
    },
    {
      nameSub: 'Type',
      codeSub: 'Travel Type',
      pointSub: 'typeFormat',
      typeSection: 'SET_TYPE_FORMAT',
    },
  ],
  'Baby Apparels': [
    {
      nameSub: 'Gender',
      codeSub: 'Gender',
      pointSub: 'gender',
      typeSection: 'SET_GENDER',
    },
    {
      nameSub: 'Size',
      codeSub: 'Size',
      pointSub: 'size',
      typeSection: 'SET_SIZE',
    },
  ],
  Fashion: [
    {
      nameSub: 'Size',
      codeSub: 'Size',
      pointSub: 'size',
      typeSection: 'SET_SIZE',
    },
  ],
  'Baby Cologne': [
    {
      nameSub: 'Scents',
      codeSub: 'Scent',
      pointSub: 'scents',
      typeSection: 'SET_SCENTS',
    },
  ],
  'Bedding & Bathroom': [
    {
      nameSub: 'Material',
      codeSub: 'Material',
      pointSub: 'material',
      typeSection: 'SET_MATERIAL',
    },
  ],
  Toys: [
    {
      nameSub: 'Material',
      codeSub: 'Material',
      pointSub: 'material',
      typeSection: 'SET_MATERIAL',
    },
  ],
  'Nursing & Feeding': [
    {
      nameSub: 'Material',
      codeSub: 'Material',
      pointSub: 'material',
      typeSection: 'SET_MATERIAL',
    },
    {
      nameSub: 'Flavours',
      codeSub: 'Flavours',
      pointSub: 'flavours',
      typeSection: 'SET_FLAVOURS',
    },
  ],
};

export const concernFilter = {
  'for-moms': {
    Skincare: [
      'acne-blemishes',
      'aging',
      'blackheads-visible-pores',
      'dullness',
      'dark-spots',
      'dark-circles',
    ],
    'Hair Care': [
      'rambut-rontok',
      'dandruff',
      'dry-scalp',
      'oiliness',
      'normal',
    ],
    'Bath & Body': ['stretch-marks', 'cellulite', 'sore-dry-flaky-nipple'],
    'Oral Care': [
      'sensitive-teeth',
      'cavity',
      'dental-plaque',
      'gum-care',
      'freshens-Breath',
    ],
  },
  'for-baby-kids': {
    'Oral Care': ['cavity', 'dental-plaque', 'gum-care'],
    'Baby Care': ['eczema'],
    Toiletries: ['bug-bite'],
    Diaper: ['diaper-rash'],
    'Hair Care': ['hair-growth', 'dry-tangled-hair', 'eczema', 'cradle-cap'],
  },
};

export const materialFilterNursing = [
  'Wood',
  'Sillicone',
  'Bamboo',
  'PP',
  'PPSU',
];
export const materialFilterToys = ['Wood', 'Silicone', 'Textile'];
