import axios from 'axios';
const Config = require('~config/default.env').default;

// const getBrandSearch = ({ keyword }) =>
//   axios
//     .get(`${Config.MS_CATALOG_API_URL}/v3/brand/search`, {
//       params: {
//         filter: { keyword },
//         limit: 3,
//       },
//     })
//     .then(({ data: { data } }) => data)
//     .catch(console.log);

// const getSearch = ({ keyword }) =>
//   axios
//     .get(`${Config.MS_CATALOG_API_URL}/v3/search`, {
//       params: {
//         filter: { keyword },
//         limit: 3,
//       },
//     })
//     .then(({ data: { data } }) => data)
//     .catch(console.log);

// const getSearchSuggestions = ({ keyword }) =>
//   axios
//     .all([getBrandSearch({ keyword }), getSearch({ keyword })])
//     .then((data) => data)
//     .catch(console.log);

const getSearchSuggestions = ({ keyword, types = ['tag', 'banner', 'category', 'brand', 'add-review'], limit = 5 }) =>
  axios
    .get(`${Config.MS_SOCO_PUBLIC_API_URL}/global-search`, {
      params: {
        filter: {
          keyword,
          types,
          is_old_request: true,
          is_old_response: false,
          skip_counts: true,
          is_global_search: true,
        },
        limit,
        skip: 0,
      },
    })
    .then(({ data: { data } }) => data)
    .catch(console.log);

const getPopularProducts = () =>
  axios
    .get(`${Config.MS_ANALYTICS_API_URL}/product/ppr`, {
      params: {
        filter: {
          applicable_for: { $in: ['lilla'] },
        },
        sort: '-created_at',
        fields:
          'id name brand images review_stats total_reviews default_category',
        skip: 0,
        limit: 10,
      },
    })
    .then(({ data: { data } }) => data)
    .catch(console.log);

const getPopularBrands = () =>
  axios
    .get(`${Config.MS_CATALOG_API_URL}/v3/brands`, {
      params: {
        filter: { is_featured_lulla: true },
        sort: '-total_reviews',
        skip: 0,
        limit: 5,
      },
    })
    .then(({ data: { data } }) => data)
    .catch(console.log);

const getRecentlySearched = () =>
  axios
    .get(`${Config.MS_SOCO_PUBLIC_API_URL}/search-history-keywords`, {
      params: {
        limit: 5,
        filter: {
          source_from: { $in: ['lulla_ios', 'lulla_android', 'lulla '] },
        },
      },
    })
    .then(({ data: { data } }) => data)
    .catch(console.log);

const setHistoryClicks = (payload) =>
  axios
    .post(`${Config.MS_SOCO_PUBLIC_API_URL}/search-history-clicks`, payload)
    .then(({ data: { data } }) => data)
    .catch(console.log);

const unsetHistoryClicks = () =>
  axios
    .delete(`${Config.MS_SOCO_PUBLIC_API_URL}/search-history-keywords-clear`)
    .then(({ data: { data } }) => data)
    .catch(console.log);

export default {
  getSearchSuggestions,
  getRecentlySearched,
  getPopularBrands,
  getPopularProducts,
  setHistoryClicks,
  unsetHistoryClicks,
};
