import analytics from '@react-native-firebase/analytics';
// const Config = require('~config/default.env').default;
import { Platform } from 'react-native';
// import { BranchEvent } from 'react-native-branch';
const $get = require('lodash.get');

const logEvent = ({ eventName, data }) => {
  analytics().logEvent(eventName, data);
};

const logPurchase = ({ data, cartDetail }) => {
  try {
    // const productBrands = [];
    // const productIds = [];
    // const productNames = [];
    // const combinationNames = [];
    // const categoryParents = [];
    // const categoryChilds = [];
    // const categoryGrandChilds = [];
    // data.products.map((res) => {
    //   if (res.combination && Object.keys(res.combination).length) {
    //     for (const i in res.combination) {
    //       if (i !== 'id') {
    //         combinationNames.push(res.combination[i].name);
    //       }
    //     }
    //   }
    //   if (res?.brand?.name) {
    //     productBrands.push(res.brand.name);
    //   }
    //   if (res?.id) {
    //     productIds.push(res.id);
    //   }
    //   if (res?.name) {
    //     productNames.push(res.name);
    //   }
    // });
    // cartDetail.products.map((res) => {
    //   if (res?.categories && res.categories.length) {
    //     categoryParents.push(
    //       res.categories[0]?.name ? res.categories[0]?.name : '',
    //     );
    //     if (res?.categories?.[1]) {
    //       categoryChilds.push(
    //         res.categories[1]?.name ? res.categories[1]?.name : '',
    //       );
    //     }
    //     if (res?.categories?.[2]) {
    //       categoryGrandChilds.push(
    //         res.categories[2]?.name ? res.categories[2]?.name : '',
    //       );
    //     }
    //   }
    // });
    // const branchEvent = new BranchEvent(BranchEvent.Purchase);
    // branchEvent.revenue = String(data.total_paid);
    // branchEvent.currency = 'IDR';
    // const branchCustomData = {};
    // branchCustomData.Brand = productBrands.join(', ');
    // branchCustomData.Category_Parent = categoryParents.join(', ');
    // branchCustomData.Category_Child = categoryChilds.join(', ');
    // branchCustomData.Category_GrandChild = categoryGrandChilds.join(', ');
    // branchCustomData.Product_Id = productIds.join(', ');
    // branchCustomData.Order_Id = String(data._id);
    // branchCustomData.Product_Name = productNames.join(', ');
    // branchCustomData.Combination_Name = combinationNames.join(', ');
    // branchCustomData.Quantity = String(data.total_quantity);
    // branchCustomData.Amount_Of_Purchase = String(data.total_paid);
    // branchCustomData.Payment_Method = data?.payment_method?.name
    //   ? data.payment_method.name
    //   : '';
    // branchEvent.currency = 'IDR';
    // branchEvent.customData = branchCustomData;
    // branchEvent.logEvent();
  } catch (err) {
    console.log(err);
  }
};

const logPurchaseGA = (data) => {
  try {
    const logData = {
      currency: 'IDR',
      transaction_id: data._id,
      value: data.total_paid,
      affiliation: `Lilla ${Platform.OS}`,
      coupon: data.voucher_applied ? data.voucher_applied : '',
      shipping: data.total_shipping,
      tax: 0,
      payment_type: data?.payment_method?.name ? data.payment_method.name : '',
      items: data.products.map((res) => {
        const combinationName = [];
        if (res.combination && Object.keys(res.combination).length) {
          for (const i in res.combination) {
            if (i !== 'id') {
              combinationName.push(res.combination[i].name);
            }
          }
        }
        return {
          item_id: res.id,
          item_name: res.name,
          affiliation: `Lilla ${Platform.OS}`,
          coupon: data.voucher_applied ? data.voucher_applied : '',
          currency: 'IDR',
          discount:
            res.price > res.price_after_discount
              ? res.price - res.price_after_discount
              : 0,
          item_brand: res?.brand?.name ? res.brand.name : '',
          item_category: res?.default_category?.name
            ? res.default_category.name
            : '',
          item_category2: '',
          item_category3: '',
          item_category4: '',
          item_category5: '',
          item_variant: combinationName.length
            ? combinationName.join(', ')
            : '',
          price: res.price,
          quantity: res.quantity,
        };
      }),
    };
    analytics().logEvent('purchase', logData);
  } catch (err) {
    console.log(err);
  }
};

const logBannerCLick = (data) => {
  const { title = '', more_info = '', description = '', url = '' } = data;
  try {
    const logData = {
      banner_name: title,
      banner_location: $get(more_info, 'page_type', ''),
      banner_text: description,
      banner_url: url,
    };

    analytics().logEvent('banner_click', logData);
  } catch (err) {
    console.log(err);
  }
};

const logEgiftProduct = (data, navigation = null) => {
  try {
    if (navigation) {
      let sourceName = '';
      if (navigation) {
        try {
          const nav = navigation.getState();
          const index = nav?.index;
          sourceName = nav?.routes?.[index]?.name;
        } catch (err) {}
      }

      const eventEgiftName = 'egift_product';
      const egiftData = {
        banner_name: data.slug,
        source: sourceName,
      };

      if (Platform.OS == 'web') {
        window.dataLayer.push({
          event: eventEgiftName,
          egift_data: egiftData,
        });
      } else {
        analytics().logEvent(eventEgiftName, egiftData);
      }
    }
  } catch (err) {
    console.log('err-log-egift-product', err);
  }
};

const logCurrentScreen = (screenName) => {
  analytics().logScreenView({
    screen_name: screenName,
    screen_class: screenName,
  });
};

export {
  logEvent,
  logPurchaseGA,
  logPurchase,
  logEgiftProduct,
  logCurrentScreen,
  logBannerCLick,
};
