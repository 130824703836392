const Config = require('~config/default.env').default;
import axios from 'axios';
import { removeFalsyParams } from '~global/helpers';
const getUserPhotos = ({ id, limit }) => {
  return axios.get(
    `${Config.MS_SOCO_PUBLIC_API_URL}/products/reviews/${id}/images`,
    {
      params: {
        limit,
      },
    },
  );
};

const getTotalUserPhotos = (id) => {
  return axios.get(
    `${Config.MS_SOCO_PUBLIC_API_URL}/products/reviews/${id}/images/count`,
  );
};

const getProductReviews = (params) => {
  return axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/reviews`, { params });
};

const getProductDetail = (productId) => {
  return axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/products/${productId}`);
};
const getTotalReviews = (params) => {
  return axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/reviews/count`, {
    params,
  });
};

const reviewLike = (id) => {
  return axios.post(`${Config.MS_SOCO_PUBLIC_API_URL}/newsfeed/${id}/likes`);
};

const getUserReviews = ({ params, userId }) => {
  return axios.get(
    `${Config.MS_SOCO_PUBLIC_API_URL}/newsfeeds/user/${userId}`,
    { params },
  );
};

const getUserReviewsCount = ({ params, userId }) => {
  return axios.get(
    `${Config.MS_SOCO_PUBLIC_API_URL}/newsfeeds/user/${userId}/count`,
    { params },
  );
};

const getAppVersions = (params) => {
  return axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/app-versions/lilla`, {
    params,
  });
};

const addReview = (payload) => {
  return axios.post(`${Config.MS_SOCO_PUBLIC_API_URL}/reviews`, payload);
};

const updateReview = ({ payload, id }) => {
  return axios.put(`${Config.MS_SOCO_PUBLIC_API_URL}/reviews/${id}`, payload);
};

const getReviews = (params) => {
  return axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/reviews`, { params });
};

const reportReview = ({ payload, id }) => {
  return axios.post(
    `${Config.MS_SOCO_PUBLIC_API_URL}/newsfeed/${id}/reports`,
    payload,
  );
};

const requestToDeleteReview = (id) => {
  return axios.delete(`${Config.MS_SOCO_PUBLIC_API_URL}/newsfeed/${id}`);
};

const saveFirebaseToken = (payload) => {
  return axios.post(
    `${Config.MS_SOCO_PUBLIC_API_URL}/push-notification/save-token`,
    payload,
  );
};

const createAffiliate = (payload) =>
  axios
    .post(`${Config.MS_SOCIOLLA_PUBLIC_API_URL}/affiliates`, payload)
    .then(({ data: { data } }) => data)
    // eslint-disable-next-line no-console
    .catch(console.log);

const createContactUs = (payload) =>
  axios.post(`${Config.MS_SOCIOLLA_PUBLIC_API_URL}/queries`, payload);

const requestToDeleteAccount = (id) => {
  return axios.delete(`${Config.MS_SOCO_PUBLIC_API_URL}/user/${id}/status`);
};

const searchProductsRequest = (params) => {
  const request = params.cancelToken ? params : { params };
  const finalParams = { params: removeFalsyParams({ ...request.params }) };
  return axios.get(
    `${Config.MS_SOCO_PUBLIC_API_URL}/global-search`,
    finalParams,
  );
};

const getRewards = (params) => {
  return axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/rewards`, params);
};

const getUserReward = (userId) => {
  return axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/user/me/reward/${userId}`);
};

const getHistoryTransactions = (params) => {
  return axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/user/me/transactions`, {
    params,
  });
};

export const Soco = {
  getUserPhotos,
  getTotalUserPhotos,
  getProductReviews,
  getTotalReviews,
  reviewLike,
  getUserReviews,
  getUserReviewsCount,
  getAppVersions,
  addReview,
  getReviews,
  updateReview,
  reportReview,
  requestToDeleteReview,
  saveFirebaseToken,
  getProductDetail,
  createAffiliate,
  createContactUs,
  requestToDeleteAccount,
  searchProductsRequest,
  getRewards,
  getUserReward,
  getHistoryTransactions,
};
